import type React from 'react';
import { useState, useEffect, useMemo } from 'react';
import throttle from 'lodash.throttle';

export const FADE_UP_ANIMATION_DELAY_BETWEEN = 150;

export const FADE_UP_DURATION_IN_SECONDS = 1;
export const OPACITY_DURATION = FADE_UP_DURATION_IN_SECONDS / 2;
export const TRANSFORM_DURATION = FADE_UP_DURATION_IN_SECONDS;

export const useFadeUpAnimation = (cardRef: React.RefObject<HTMLDivElement>, delay?: number) => {
  const [isFading, setIsFading] = useState(false);
  const [hasFaded, setHasFaded] = useState(false);

  useEffect(() => {
    if (!hasFaded) {
      const element = cardRef.current;

      const addFadeUp = throttle(() => {
        if (!element) {
          return;
        }

        const position = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (position - windowHeight > 45) {
          return;
        }

        setTimeout(() => {
          setIsFading(true);

          setTimeout(() => {
            setIsFading(false);
            setHasFaded(true);
          }, FADE_UP_DURATION_IN_SECONDS * 1000);
        }, delay);
      }, 100);

      addFadeUp();

      window.addEventListener('scroll', addFadeUp);

      return () => {
        window.removeEventListener('scroll', addFadeUp);
      };
    }
  }, [cardRef, delay, hasFaded]);

  const animationClasses = useMemo(
    () => [
      !hasFaded &&
        `768:duration-[${OPACITY_DURATION}s,${TRANSFORM_DURATION}s] 768:ease-[ease-out,ease-out] 768:opacity-0 768:transition-[opacity,transform] 768:translate-y-[50px]`,
      isFading && '!opacity-100 !translate-y-0'
    ],
    [hasFaded, isFading]
  );

  return animationClasses;
};
